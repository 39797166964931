import { Environment, WindowWithEnvironment } from '@dmv/common';

declare let window: WindowWithEnvironment;

export const environment: Environment = {
  apiUrl: '',
  authenticationConfiguration: {
    okta: {
      clientId: '',
      issuer: '',
      postLogoutRedirectUri: '',
      redirectUri: '',
      scopes: [''],
      state: 'originalUri',
      tokenManager: {
        storage: '',
      },
    },
  },
  barcodeUrlPrefix: '',
  eventsConfiguration: {
    googleTagManager: {
      id: '',
    },
  },
  featureFlagConfiguration: {
    launchDarkly: {
      apiKey: '',
    },
  },
  idUpgradeQmaticOffice: '',
  production: true,
  qmaticServiceIds: {
    changeNameOnId: '',
    exchangeOutOfStateLicense: '',
    idUpgrade: '',
    nonDriverId: '',
    permitId: '',
  },
  registerRedirectUrls: {
    licenseReciprocity: '',
    originalNdid: '',
    originalPermit: '',
    originalRegistration: '',
    realId: '',
  },
  smsReplyPhone: '',
  ...window.environment, // must be last
};
